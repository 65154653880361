import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './TooltipButton.css';

export default ({
    className = '',
    tooltip = '',
    disabled = false,
    placement = 'top',
    delayShow = 300,
    delayHide = 150,
    ...props
}) => {
    const button = (
        <Button
            disabled={disabled}
            className={`TooltipButton ${className}`}
            {...props}
        />
    );

    if (disabled || !tooltip) {
        // Just return the plain button
        return button;
    }

    return (
        <OverlayTrigger
            placement={placement}
            delayShow={delayShow}
            delayHide={delayHide}
            overlay={
                <Tooltip id="tooltip">
                    <strong>{tooltip}</strong>
                </Tooltip>
            }
            trigger={['hover', 'focus']}
        >
            {button}
        </OverlayTrigger>
    );
};
